<template>
  <v-main>
    <v-container class="mt-3">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <span class="text-h5 black--text">Netzwerkpartner</span>
          </div>
        </v-card-text>
      </v-card>

    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Network"
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>